import flJQuery from '../utils/fl-jquery';
import flFrontend from './frontend';
import flUtils from '../utils/utils';
import flConstants from '../constants';
import flRequest from './request';
import flINTERNAL_QUERY_PARAMS from '../data/internalQueryParams.json';
import flNavigationType from '../data/navigationTypes.json';
import flConfig from '../config';
import {
    analyticsTracker as flAnalyticsTracker,
    findologicTracker as flFindologicTracker,
    logger as flLogger,
    url as flUrlUtils,
} from '@findologic/js-common';
import ShopSystem from './shopsystem/shopsystem';
import { set as _set, isEqual } from 'lodash-es';
import { EventNames } from '../../tscoba/src/Common/enums.ts';

// We need those imports as vars because they are used in direct integrations eval context
const jQuery = flJQuery;
const utils = flUtils;
const frontend = flFrontend;
const constants = flConstants;
const config = flConfig;
const Request = flRequest;
const AnalyticsTracker = flAnalyticsTracker;
const FindologicTracker = flFindologicTracker;
const urlUtils = flUrlUtils;
const logger = flLogger;
const INTERNAL_QUERY_PARAMS = flINTERNAL_QUERY_PARAMS;
const NavigationType = flNavigationType;

let unmodifiedShopSystem;
const directIntegration = {
    /**
     * Number of milliseconds to wait for an operation result before reverting to the shop's built-in search
     * after a brief wait to allow error tracking to log the timeout.
     */
    OPERATION_TIMEOUT_MS: 3000,

    /**
     * ID of the loading screen element. If you change the value, make sure to adapt the style in
     * fl_autocomplete.css.twig accordingly!
     */
    LOADING_SCREEN_ID: 'flLoadingScreen',

    /**
     * ID of the loading screen's loading indicator element. If you change the value, make sure to adapt the
     * style in fl_autocomplete.css.twig accordingly!
     */
    LOADING_INDICATOR_ID: 'flLoadingIndicator',

    /**
     * Error callback cause in case of an exception in the pre-something callback.
     */
    ERROR_PRE_SOMETHING_CALLBACK_FAULTY: 'Error in pre-something callback: ',

    /**
     * Error callback cause in case of an exception in the success callback.
     */
    ERROR_SUCCESS_CALLBACK_FAULTY: 'Error in success callback: ',

    /**
     * Error callback cause in case of a long-running operation that exceeds OPERATION_TIMEOUT.
     */
    ERROR_TIMEOUT: 'Operation took too long, falling back to shop search: ',

    /**
     * The error header sent by the frontend containing the error message
     */
    FINDOLOGIC_ERROR_HEADER: 'X-FINDOLOGIC-Error',

    /**
     * Marks hidden fields in the search form that were added by Guided Shopping. Those should be ignored when
     * sending a search. The marker attribute is applied by the Guided Shopping module.
     */
    GUIDED_SHOPPING_MARKER_ATTRIB: 'data-is-guided-shopping-filter',

    /**
     * Document can have one of these readyStates
     */
    DOCUMENT_READYSTATE_LOADING: 'loading',
    DOCUMENT_READYSTATE_LOADED: 'loaded',
    DOCUMENT_READYSTATE_INTERACTIVE: 'interactive',
    DOCUMENT_READYSTATE_COMPLETE: 'complete',

    /**
     * The DOMContentLoaded event is fired when the initial HTML document has been completely loaded and parsed,
     * without waiting for stylesheets, images, and subframes to finish loading
     *
     * @link https://developer.mozilla.org/de/docs/Web/Events/DOMContentLoaded
     */
    DOM_CONTENT_LOADED_EVENT: 'DOMContentLoaded',
};

// Since frontend V3 breaks compatibility with the classic frontend, we need to resolve the proper selectors
// depending on the integrated shop's frontend version.
const selectorMapping = {
    classic: {
        link: '.flFilter a, #flPaginator a, .flChosenFilter a, .flRangeSlider a',
        select: '.flFilterBox select',
        rangeSlider: '.flRangeSlider',
        pageSize: '#flPageSizeForm > select',
        sort: '#flSortForm > select',
        filterBox: '.flFilterBox',
    },
    v3: {
        link:
            '.fl-filter a, .fl-pagination-container a, .fl-chosen-filter a, ' +
            '.fl-range-slider a, a.fl-breadcrumb-filter, a.fl-remove-all-filters-button, ' +
            '.fl-smart-did-you-mean a',
        select: '',
        rangeSlider: '.fl-range-slider',
        pageSize: '.fl-page-size-form > select',
        sort: '.fl-sort-form > select',
        filterBox: '.fl-filter-box',
    },
};

// Feature mapping for the different operation types (search, navigation, Smart Suggest).
const typeConfig = {};
typeConfig[Request.TYPE_SEARCH] = {
    successCallback: 'searchSuccess',
    preSomethingCallback: 'preSearch',
    hashKey: '#search:',
};
typeConfig[Request.TYPE_NAVIGATION] = {
    successCallback: 'navigationSuccess',
    preSomethingCallback: 'preNavigation',
    hashKey: '#navigation:',
};
typeConfig[Request.TYPE_SUGGEST] = {
    hashKey: '#suggest:',
};

let searchForm;

/**
 * If set to true, the search form is submitted regularly instead of talking to FINDOLOGIC, which serves as
 * fallback in case of an outage.
 *
 * @type {boolean}
 */
let revertToShopSearch = false;

/**
 * Maps generic names to selectors for elements to intercept for reloading filters. The
 * mapping is assigned on initialization based on the frontend
 * type.
 */
let mappedSelectors;

/**
 * The last focused searchField to use the right field respectively form when the cursor did not remain in an field.
 * For instance after tipwriting in the field, the submit-button is clicked
 *
 * @type {HTMLElement}
 */
let lastFocusedSearchField = null;

/**
 * Returns the latest selected filter as a URL query string or undefined if none was selected.
 *
 * @param {{}} query
 * @returns {string|undefined}
 */
function getLastSelectedFilter(query) {
    const trackableFilters = [];
    const previouslySelectedFilters = flUtils.mapQueryFiltersToArray(directIntegration.previousQuery);
    const currentlySelectedFilters = flUtils.mapQueryFiltersToArray(query);

    for (let i = 0; i < currentlySelectedFilters.length; i++) {
        let index = -1;

        for (let j = 0; j < previouslySelectedFilters.length; j++) {
            if (JSON.stringify(previouslySelectedFilters[j]) === JSON.stringify(currentlySelectedFilters[i])) {
                index = j;
                break;
            }
        }

        if (index === -1 && currentlySelectedFilters[i].name !== constants.ATTRIB_CAT_URL) {
            trackableFilters.push(currentlySelectedFilters[i]);
        }
    }

    if (trackableFilters.length) {
        return trackableFilters[trackableFilters.length - 1];
    } else {
        return undefined;
    }
}

/**
 * Last known URL hash, to avoid sending duplicate requests.
 *
 * @type {{}} The last known URL hash.
 */
directIntegration.previousQuery = {};

/**
 * The jQuery XMLHttpRequest object of the last sent search, so any ongoing search can be aborted when sending
 * a new one.
 *
 * @type {undefined|XMLHttpRequest}
 */
directIntegration.previousJqXhr = undefined;

/**
 * If true, a call to init() will not initialize the Direct Integration
 *
 * @type {boolean}
 */
directIntegration.preventInitialization = false;

/**
 * Is only set if Instant Product Listing is active. It is used to load results when
 * using the browser history. The string matches the queryString of the first
 * visited cat_url.
 *
 * @type {string|null}
 */
directIntegration.firstNavigationPage = null;

/**
 * Load the requested content through JSONP.
 * This wraps directIntegration.loadContent(), which waits for one of these cases:
 * 1. Either until the document.readyState is not 'loading' anymore
 * 2. Or the event 'DOMContentLoaded' has been triggered
 * 3. Or the timeout of 1 sec. has been expired
 * This happens, to ensure callbacks can use the entirety of the DOM when inserting results.
 * This is necessary because initialization can take place before the DOM elements have loaded,
 * but result insertion doesn't make sense at this time.
 *
 * @see {@link directIntegration.loadContent}
 */
directIntegration.loadContentWhenReady = function (type, queryString, callback, causedByHistory, focusedSearchField) {
    // The loadContent method should be called after 1 sec. if not one of the other cases below do apply.
    const watchdog = setTimeout(function () {
        directIntegration.loadContent(type, queryString, callback, causedByHistory, focusedSearchField);
    }, 1000);

    // If the document.readyState has reached one of the following state, it is save to load the content
    const readyState = directIntegration.getReadyState();
    if (
        readyState === directIntegration.DOCUMENT_READYSTATE_COMPLETE ||
        readyState === directIntegration.DOCUMENT_READYSTATE_LOADED ||
        readyState === directIntegration.DOCUMENT_READYSTATE_INTERACTIVE
    ) {
        clearTimeout(watchdog);
        directIntegration.loadContent(type, queryString, callback, causedByHistory, focusedSearchField);
    } else {
        // This event fires when the DOM elements are loaded
        document.addEventListener(directIntegration.DOM_CONTENT_LOADED_EVENT, function () {
            clearTimeout(watchdog);
            directIntegration.loadContent(type, queryString, callback, causedByHistory, focusedSearchField);
        });
    }
};

/**
 * Load the requested content through JSONP
 *
 * The URL is sent to the JSONP endpoint and the content will be displayed in the container
 *
 * The method needs to be global as the range-slider submit handler will also use it
 *
 * @param {String} type The kind of operation to perform, which may be Request.TYPE_SEARCH or Request.TYPE_NAVIGATION.
 * @param {String} [queryString] The query to send to the endpoint
 * @param {function} [callback] Called once the content has been loaded, regardless of success
 * @param {boolean} [causedByHistory] Must be true if the request is triggered by a history event, which is in
 * request but used from queryString, and the hash is not updated, because history takes care of that for us.
 * @param {jQuery} [focusedSearchField] HTML-Element holds focus right now or was blurred recently.
 */
// Suppressing complexity warning, because refactoring DI isn't worth it.
// eslint-disable-next-line complexity
directIntegration.loadContent = function (type, queryString, callback, causedByHistory, focusedSearchField) {
    const self = this;
    const allFields = config.searchFieldElement;
    focusedSearchField = focusedSearchField || flUtils.getFocusedSearchField(allFields);

    // The success callback is defined in the customer-login and passed via the autocomplete config. It needs to
    // be a JS function, so "eval('(function () { /* ... */ })')" can return the parsed function object.
    // eslint-disable-next-line no-eval
    const successCallback = eval(`(${config.directIntegration.callbacks[typeConfig[type].successCallback]})`);

    // eslint-disable-next-line no-eval
    const preSomethingCallback = eval(`(${config.directIntegration.callbacks[typeConfig[type].preSomethingCallback]})`);

    // Initialize params with the fields in the search form.
    const params = urlUtils.parseQueryString(utils.getClosestForm(focusedSearchField).serialize());

    // With navigation requests, the URL is passed as an additional param to ensure that navigation remains
    // within a certain category.
    if (type === Request.TYPE_NAVIGATION) {
        _set(params, `attrib.${constants.ATTRIB_CAT_URL}`, [window.location.pathname]);
    }

    // Parse the given query string for a clean merge. From the search form, only consider the query, not hidden
    // filters set by selecting a Smart Suggest suggestion. When using suggestions, the filters are provided in
    // queryString anyway, so there is no need to use the full search form.
    const parsedQuery = queryString ? urlUtils.parseQueryString(queryString) : {};
    if (!causedByHistory) {
        params.query = focusedSearchField.val();
    }

    // You can't have a query when you do a navigation request.
    if (type === Request.TYPE_NAVIGATION) {
        delete params.query;
    }

    // Use search field name that is configured in the customer-login. Otherwise the frontend will not know
    // what was searched, because it only knows "query" as parameter. Instead of sending "q=something" to the
    // frontend, we send "query=something".
    if (
        type === Request.TYPE_SEARCH &&
        typeof params.forceOriginalQuery === 'undefined' &&
        !!params[config.searchFieldName] && // Check falsiness to guard against all invalid values.
        !flUtils.isMobile(config)
    ) {
        params.query = params[config.searchFieldName];
    }

    // The full search query string.
    const mergedParams = jQuery.extend(true, {}, params, parsedQuery);
    let fullQueryString = jQuery.param(mergedParams);
    let queryObj;

    if (this.glue) {
        queryObj = this.glue.beforeOperation(type, mergedParams);
        fullQueryString = jQuery.param(queryObj);
    } else {
        // The pre-search callback may optionally override the query string. If nothing is returned, the regular
        // query string is used.
        try {
            let queryStringOverride;
            if (!window.location.href.includes('ignorePreSomethingCallback')) {
                queryStringOverride = preSomethingCallback(fullQueryString);
            }

            if (queryStringOverride) {
                fullQueryString = queryStringOverride;
            }

            queryObj = urlUtils.parseQueryString(fullQueryString);
        } catch (e) {
            const callbackName = typeConfig[type].preSomethingCallback;

            directIntegration.callErrorCallbackSafely(
                directIntegration.ERROR_PRE_SOMETHING_CALLBACK_FAULTY,
                e,
                type,
                fullQueryString,
                callbackName,
                function (doFallback) {
                    // eslint-disable-next-line no-console
                    console.warn(e);

                    if (doFallback) {
                        directIntegration.fallBackToShopSearch(`faulty ${callbackName} callback`);
                    }
                }
            );

            // Skip DI and fallback to the shop search if the callbacks are faulty.
            return;
        }
    }

    if (this.shoppingGuide) {
        this.shoppingGuide.setParams(type, queryObj);
    }

    /**
     * In case of timeout or error, calling this method causes shop search to be used instead of DI.
     */
    function logErrorAndFallBack(cause, trackingFingerprint) {
        directIntegration.callErrorCallbackSafely(
            directIntegration.ERROR_TIMEOUT,
            null,
            type,
            fullQueryString,
            cause,
            function (doFallback) {
                // eslint-disable-next-line no-console
                console.warn(`${cause}: ${trackingFingerprint}`);
                if (doFallback) {
                    directIntegration.fallBackToShopSearch(`${type} ${cause}`);
                }
            }
        );
    }

    const request = new Request({
        timeout: directIntegration.OPERATION_TIMEOUT_MS,

        // Since JSONP and CORS don't support error handlers in jQuery, this timeout takes effect unless
        // it is cleared once the request is complete. Overly long and failed searches are caught this
        // way, so the user can fall back to the shop's built-in search.
        onTimeout() {
            logErrorAndFallBack('timeout', 'directIntegrationTimeout');
        },

        onError(response) {
            const findologicError = response.getResponseHeader(directIntegration.FINDOLOGIC_ERROR_HEADER);
            if (findologicError) {
                // eslint-disable-next-line no-console
                console.error(findologicError);
            }

            logErrorAndFallBack('error', 'directIntegrationError');
        },
        // Suppressing complexity warning, because refactoring DI isn't worth it.
        // eslint-disable-next-line complexity
        onSuccess(response) {
            // Navigation success also receives a flag indicating whether any results are available. We
            // know results should not be displayed if this is the first navigation query, which is known from
            // the query. DO, however, show results if the URL explicitly indicates that this is a
            // deliberate navigation operation.
            const hashOperation = directIntegration.getHashQuery().type;
            const resultsAvailable = self.glue
                ? JSON.parse(response)?.result?.items?.length
                : !queryObj.hasOwnProperty('isNavigationQuery') || hashOperation === Request.TYPE_NAVIGATION;

            if (self.glue) {
                if ((!causedByHistory && type === Request.TYPE_SEARCH) || resultsAvailable) {
                    directIntegration.updateHash(type, fullQueryString);
                }

                self.glue.afterOperation(type, JSON.parse(response));

                if (directIntegration.isInitialNavigationCall) {
                    directIntegration.trackNavigation();
                }

                // Skip remaining DI execution - all handled by Instant Frontend from here on out.
                return;
            }

            // The string response is converted to HTML, which is at first not inserted into the DOM. Insertion
            // is the job of the success callback.
            const newContent = jQuery('<div/>').html(response);

            // Prevent further execution, if this is supposed to be navigation, but the current page is not a
            // category page. We detect that by checking if a navigation request contains filters.
            // TODO: Less heuristic solution in a future version.
            if (type === Request.TYPE_NAVIGATION && newContent.find(mappedSelectors['filterBox']).length < 1) {
                directIntegration.revealContainerIfSupported();

                return;
            }

            // Prevent further execution, if response is landingpage and navigate directly to the url.
            if (directIntegration.redirectIfLandingpage(newContent)) {
                return;
            }

            // Install handlers to allow filtering.
            self.installHandler(type, newContent);

            // Write the query to the URL hash to enable navigation. This needs to happen before the success
            // callback, so the it can override the hash at will without the override being replaced with the
            // standardized query string right away.
            //
            // IMPORTANT EXCEPTION: Don't do this for the very first navigation request in order to keep the URL
            // clean of a redundant attrib[cat_url][] filter.
            if ((!causedByHistory && type === Request.TYPE_SEARCH) || resultsAvailable) {
                directIntegration.updateHash(type, fullQueryString);
            }

            // Be careful with executing the success callback, so a simple error won't break all JS on the site.
            try {
                const paramsCopy = JSON.parse(JSON.stringify(queryObj));
                if (type === Request.TYPE_SEARCH) {
                    successCallback(newContent, paramsCopy);
                } else if (type === Request.TYPE_NAVIGATION) {
                    successCallback(newContent, resultsAvailable, paramsCopy);

                    if (directIntegration.isInitialNavigationCall) {
                        directIntegration.trackNavigation();
                    }
                }

                // Reveal containers after success callback has been completed.
                directIntegration.revealContainerIfSupported();
            } catch (e) {
                // Reveal containers if an error in the callback occurred.
                directIntegration.revealContainerIfSupported();
                const callbackName = typeConfig[type].successCallback;

                directIntegration.callErrorCallbackSafely(
                    directIntegration.ERROR_SUCCESS_CALLBACK_FAULTY,
                    e,
                    type,
                    fullQueryString,
                    callbackName,
                    function (doFallback) {
                        const optionalData = {
                            tags: {
                                operation: type,
                            },
                            extra: {
                                queryString: fullQueryString,
                            },
                            fingerprint: [callbackName, config.shopkey],
                        };

                        optionalData.tags[constants.ERROR_TRACKER_CALLBACK_TAG] = callbackName;

                        // eslint-disable-next-line no-console
                        console.warn(e);

                        if (doFallback) {
                            directIntegration.fallBackToShopSearch(`faulty ${callbackName} callback`);
                        }
                    }
                );
            }

            // Since the filters are newly appended to the DOM, they need to be initialized again.
            frontend.initializeFrontend(config.frontendConfig, config.cdnBaseUrl);
            frontend.initializeRangeSliders();

            // Removes hidden inputs from the search form because the filters
            // should not be set anymore when triggering a new search
            unmodifiedShopSystem.removeSpecificItemsFromForm();

            callback ? callback() : null;
        },
        onComplete() {
            directIntegration.isInitialNavigationCall = false;
            // Clear the previous request's jQuery XMLHttpRequest object that is used for aborting ongoing
            // requests so the internal state is clean.
            directIntegration.previousJqXhr = undefined;
        },
    });

    // Only send the query if it's different from the previous one to prevent double submission. We have to
    // check for this just before sending to ensure that all possible modifications of the query have
    // been made before this point.
    //
    // The parameters must be free from internal values, because they can vary from request to request, e.g.
    // request ID or a manually added cache buster.
    const sanitizedCurrentQuery = directIntegration.sanitizeQueryParams(queryObj);
    const sanitizedPreviousQuery = directIntegration.sanitizeQueryParams(directIntegration.previousQuery);

    // The simple solution to compare two objects would be stringifying them and comparing the strings. The
    // order of properties is not entirely deterministic, though. This could cause queries being judged to be
    // unequal if it has the same parameters but in a different order. To avoid that, do a deep order-less
    // comparison.

    // Extra condition for pushAttrib because they will be sanitized by default
    if (
        !flUtils.equalObjects(sanitizedCurrentQuery, sanitizedPreviousQuery) ||
        !isEqual(queryObj.pushAttrib, directIntegration.previousQuery.pushAttrib)
    ) {
        // Track the search and filter clicks, if enabled.
        directIntegration.trackSearchWithGoogle(fullQueryString, type);

        if (typeof directIntegration.previousJqXhr !== 'undefined') {
            directIntegration.previousJqXhr.abort();
            directIntegration.previousJqXhr = undefined;
        }

        // Store a snapshot of the previous query to avoid accidental modifications by reference elsewhere.
        directIntegration.previousQuery = jQuery.extend(true, {}, queryObj);

        directIntegration.previousJqXhr = request.send(type, queryObj);
    }
};

/**
 * Removes internal keys from query parameters so they don't influence user-visible content.
 *
 * @param {{}} queryParams Query parameters to sanitize.
 * @returns {{}} Sanitized copy of the input query parameters.
 */
directIntegration.sanitizeQueryParams = function (queryParams) {
    // Create a copy to prevent accidental side-effects.
    const queryParamsCopy = jQuery.extend({}, queryParams);

    jQuery.each(INTERNAL_QUERY_PARAMS, function (i, param) {
        delete queryParamsCopy[param];
    });

    return queryParamsCopy;
};

/**
 * Inserts the loading screen into the element specified by the given selector. It will be visible immediately
 * and cover the entire container. The loading screen has an absolute position, so it is anchored to the first
 * parent with relative position.
 *
 * This method is intended to be called in the pre-search callback. Conversely,
 * call directIntegration.hideLoadingScreen() in the success callback.
 *
 * @param {String} containerSelector CSS selector of the element which will contain the search results.
 */
directIntegration.showLoadingScreen = function (containerSelector) {
    const container = jQuery(containerSelector);
    const loadingScreen = jQuery(
        `<div id="${directIntegration.LOADING_SCREEN_ID}"><div id="${directIntegration.LOADING_INDICATOR_ID}"></div></div>`
    );
    container.prepend(loadingScreen);
};

directIntegration.removeDefaultInstantFrontendCount = function (queryParameters) {
    if (
        config.instantFrontend.productListingPage.enabled &&
        parseInt(queryParameters.count, 10) === config.instantFrontend.productListingPage.defaultPageSize
    ) {
        delete queryParameters.count;
    }
};

/**
 * Removes the loading screen from the DOM. The element specified by the given selector will be searched for the
 * loading screen.
 *
 * This method is intended to be called in the success callback. It only makes sense assuming in the pre-search
 * callback, directIntegration.showLoadingScreen() has been called before.
 *
 * @param {String} containerSelector CSS selector of the element which contains the search results.
 */
directIntegration.hideLoadingScreen = function (containerSelector) {
    const container = jQuery(containerSelector);
    const loadingScreen = jQuery(container.find(`#${this.LOADING_SCREEN_ID}`));
    loadingScreen.remove();
};

/**
 * Scrolls to the top of the page, or, if a selector is given, to the top of the selected element.
 *
 * @param {String} [selector] The selector to whose top should be scrolled. If none is given, we will scroll to the
 * top of the page.
 */
directIntegration.scrollToTop = function (selector) {
    const targetSelector = selector ? selector : 'body';

    jQuery('html, body').animate(
        {
            scrollTop: jQuery(targetSelector).offset().top,
        },
        'slow'
    );
};

directIntegration.isFirstNavigationRequest = function (type) {
    const isInstantNavigationActive = config.directIntegration.navigationType === NavigationType.INSTANT_PRODUCT_LISTING;
    const isInstantFrontendNavigationActive =
        config.instantFrontend.productListingPage.enabled &&
        config.instantFrontend.productListingPage.navigationConfig.implementation === 'instant';

    if (type === Request.TYPE_NAVIGATION && (isInstantNavigationActive || isInstantFrontendNavigationActive)) {
        const previousQueryWithoutInternalParams = jQuery.extend({}, directIntegration.previousQuery);

        directIntegration.removeDefaultInstantFrontendCount(previousQueryWithoutInternalParams);

        jQuery.each(INTERNAL_QUERY_PARAMS, function (i, param) {
            delete previousQueryWithoutInternalParams[param];
        });

        const onlyOneUrlParameterIsRequested = Object.keys(previousQueryWithoutInternalParams).length === 1;

        let catUrlIsDefined = false;
        let attribCount = 0;
        if (previousQueryWithoutInternalParams.hasOwnProperty('attrib')) {
            catUrlIsDefined = previousQueryWithoutInternalParams.attrib.hasOwnProperty(constants.ATTRIB_CAT_URL);
            attribCount = Object.keys(previousQueryWithoutInternalParams.attrib).length;
        }

        // If the only attribute requested is a cat_url, we know this is the first navigation
        // request.
        return onlyOneUrlParameterIsRequested && catUrlIsDefined && attribCount === 1;
    }

    return false;
};

/**
 * Replaces the URL hash with the query string to enable navigation.
 * Internal query parameters are removed (i.e. shopurl, shopkey, isNavigationQuery, usergrouphash).
 *
 * @param {String} type The kind of operation to perform, which may be Request.TYPE_SEARCH or Request.TYPE_NAVIGATION.
 * @param {String} queryString The current search results' query string.
 */
directIntegration.updateHash = function (type, queryString) {
    unmodifiedShopSystem.beforeDIHashchange();

    if (directIntegration.isFirstNavigationRequest(type)) {
        // Since we check above if the cat_url is set, we can be sure that it is always set.
        directIntegration.firstNavigationPage = queryString;
        // Clearing the current hash is required for browser history (e.g. selecting a
        // filter and going back should clear the current hash).
        window.history.replaceState('', document.title, window.location.pathname + window.location.search);
    } else {
        // Remove internal query parameters.
        const parsedQueryString = urlUtils.parseQueryString(queryString);
        directIntegration.removeDefaultInstantFrontendCount(parsedQueryString);
        queryString = jQuery.param(directIntegration.sanitizeQueryParams(parsedQueryString));

        // Firefox automatically URL-decodes the hash when setting window.location.hash so that
        // '%5B' becomes '[', this can be avoided by setting the href.
        const href = window.location.href.split('#')[0];
        const hash = typeConfig[type].hashKey + queryString;

        // We only want to change the location if there really is something to change for.
        if (window.location.href !== href + hash) {
            window.location.href = href + hash;
        }
    }

    unmodifiedShopSystem.afterDIHashchange();
};

/**
 * Causes a regular shop search instead of a Direct Integration search. To be used in case of faulty callbacks,
 * or if the search result doesn't arrive in time.
 *
 * @param [reason] Reason to fall back to shop search for logging purposes.
 */
directIntegration.fallBackToShopSearch = function (reason) {
    let logline = 'Falling back to shop search.';
    if (reason) {
        logline += ` Reason: ${reason.toString()}`;
    }
    // eslint-disable-next-line no-console
    console.warn(logline);

    // TODO: Set the fallback flag for sticky fallback once we're confident enough that this won't impact users
    // negatively in real life.
    // utils.setFallbackFlag();

    this.setRevertToShopSearch(true);
    searchForm.submit();
};

/**
 * Set the option to revert to the shop own search.
 * This method exists because it will be overwritten in the tests.
 *
 * @param {boolean} state
 */
directIntegration.setRevertToShopSearch = function (state) {
    revertToShopSearch = state;
};

/**
 * Checks if the page hash contains the query string. If yes, the appropriate search results are loaded and, if
 * needed, the search field is updated.
 *
 * To be called on page load and on hash changes.
 *
 * @return {String} Type of operation requested by the hash, or undefined if there is no useful hash.
 */
directIntegration.onHashChangeLoadContent = function () {
    const hashQuery = directIntegration.getHashQuery();

    if ([Request.TYPE_SEARCH, Request.TYPE_NAVIGATION].indexOf(hashQuery.type) > -1) {
        // Set the query string in the search field, which is relevant in case of a linked search.
        const parsedQuery = urlUtils.parseQueryString(hashQuery.query);
        if (parsedQuery && 'query' in parsedQuery) {
            directIntegration.updateSearchFieldsWithValue(parsedQuery.query);
        }

        directIntegration.loadContentWhenReady(hashQuery.type, hashQuery.query, undefined, true);
    }

    return hashQuery.type;
};

directIntegration.updateSearchFieldsWithValue = function (value) {
    config.searchFieldElement.val(value);
};

/**
 * Checks what kind of operation is being performed, based on the hash, and returns the operation type and the
 * query string. If the hash doesn't contain interesting data, the returned type is undefined.
 *
 * @param {string} url The URL from which the hash should be extracted. This can also be a URL fragment, as long as
 * it contains a "#" followed by the full hash, if available.
 * @returns {{type: string|undefined, query: string|undefined}} Type of operation and query string.
 */
directIntegration.getHashQuery = function (url) {
    if (typeof url === 'undefined') {
        /*
         * Firefox will automatically URL-decode the hash in window.location.hash so #&%26 will return '&&'. The
         * only way to get the encoded hash is to use window.location.href
         */
        url = window.location.href;
    }

    const hashQuery = {
        type: undefined,
        query: undefined,
    };

    jQuery.each(typeConfig, function (type, config) {
        let hash = url.split('#')[1] || '';

        /* To emulate the browser behavior, the hash must begin with '#' */
        hash = `#${hash}`;

        if (hash.indexOf(config.hashKey) === 0) {
            hashQuery.type = type;
            hashQuery.query = hash.split(config.hashKey)[1];

            return false;
        }
    });

    return hashQuery;
};

/**
 * Install the handlers to turn requests into AJAX requests
 *
 * This will handle filters, pagination, sorting etc.
 *
 * @param {String} type Type of load operation that should be used upon interaction. May be Request.TYPE_SEARCH
 * or Request.TYPE_NAVIGATION.
 * @param {jQuery} container The container containing the elements to manipulate
 * @todo Merge with guided shopping
 */
directIntegration.installHandler = function (type, container) {
    const self = this;
    const analyticsTracker = AnalyticsTracker.getInstance(config, false);
    const findologicTracker = FindologicTracker.getInstance(config);

    // Capture clicks on filter- and pagination links.
    const links = container.find(mappedSelectors.link);
    links.click(function () {
        // Do nothing for current page pagination link
        const isCurrentPageLink = this.parentElement.classList.contains('fl-current');
        if (isCurrentPageLink) {
            return false;
        }
        // Extract the query string from this link so it can be submitted through AJAX
        const queryString = urlUtils.getQueryString(this.href);

        self.scrollToTop();
        self.loadContentWhenReady(type, queryString);

        return false;
    });

    // Capture changes of the number of results per page.
    const pageSizeDropdown = container.find(mappedSelectors.pageSize);
    pageSizeDropdown.removeAttr('onchange');
    pageSizeDropdown.change(function () {
        const pageSizeForm = jQuery(this).parent();
        const actualUrl = pageSizeForm.serialize();

        self.loadContentWhenReady(type, actualUrl);

        return false;
    });

    // Capture range slider form submissions.
    container.find(mappedSelectors.rangeSlider).submit(function () {
        // Send the range slider's form parameters through AJAX
        const sliderForm = jQuery(this);
        const actualUrl = sliderForm.serialize();

        self.scrollToTop();
        self.loadContentWhenReady(type, actualUrl);

        return false;
    });

    // Capture result sort form submissions.
    const sortDropDown = container.find(mappedSelectors.sort);
    sortDropDown.removeAttr('onchange');
    sortDropDown.change(function () {
        const queryString = jQuery(this).parent().serialize();

        self.scrollToTop();
        self.loadContentWhenReady(type, queryString);

        return false;
    });

    // Capture select filter changes.
    container
        .find(mappedSelectors.select)
        .removeAttr('onchange')
        .change(function () {
            // The select/option's value is a full URL, extract the query string so it can be send through AJAX
            const queryString = urlUtils.getQueryString(jQuery(this).val());

            self.scrollToTop();
            self.loadContentWhenReady(type, queryString);

            return false;
        });

    container.find('[data-fl-product-placement]').on('click', function () {
        const link = jQuery(this);

        analyticsTracker.trackEvent(
            'findologicEvent',
            'FINDOLOGIC Click',
            'Product Placement',
            link.data('flProductPlacement'),
            function () {
                flUtils.navigateTo(link.attr('href'));
            },
            { event: EventNames.PRODUCT_PLACEMENT_CLICK, product_placement: link.data('flProductPlacement') }
        );

        return false;
    });

    container.find('[data-fl-search-concept]').on('click', function () {
        const link = jQuery(this);

        analyticsTracker.trackEvent(
            'findologicEvent',
            'FINDOLOGIC Click',
            'Search-Concept',
            link.data('flSearchConcept'),
            function () {
                flUtils.navigateTo(link.attr('href'));
            },
            { event: EventNames.SEARCH_CONCEPT_CLICK, search_concept: link.data('flSearchConcept') }
        );

        return false;
    });

    container.find('[data-fl-promotion] > a').on('click', function () {
        const link = jQuery(this);

        analyticsTracker.trackEvent(
            'findologicEvent',
            'FINDOLOGIC Click',
            'Promotion',
            link.parent().data('flPromotion'),
            function () {
                flUtils.navigateTo(link.attr('href'));
            },
            { event: EventNames.PROMOTION_CLICK, promotion: link.parent().data('flPromotion') }
        );

        return false;
    });

    container.find('[data-fl-bonus-rules]').on('click', function () {
        const link = jQuery(this);

        // No need to parse the JSON array in the attribute, because jQuery takes care of it for us.
        const bonusRuleNames = link.data('flBonusRules');

        // Track each bonus rule separately. Use the total count to determine when we're done and
        // can proceed to the product by counting down to zero.
        let bonusRuleCount = bonusRuleNames.length;
        jQuery.each(bonusRuleNames, function (i, bonusRuleName) {
            analyticsTracker.trackEvent(
                'findologicEvent',
                'FINDOLOGIC Click',
                'Bonus Rule',
                bonusRuleName,
                function () {
                    bonusRuleCount--;

                    if (bonusRuleCount === 0) {
                        flUtils.navigateTo(link.attr('href'));
                    }
                },
                { event: EventNames.PUSH_RULE_CLICK, push_rule: bonusRuleName }
            );
        });

        return false;
    });

    container.find('[data-fl-item-id][data-fl-item-name][data-fl-request-id][data-fl-result-index]').on('click', function () {
        const result = jQuery(this);
        // Tracker count setup
        let trackerCount = 0;

        let query = urlUtils.parseQueryString(directIntegration.getHashQuery().query).query;
        if (!query) {
            // If the query is empty/undefined/null then there is no tracking by google
            // Thats why we replace it with the following string:
            query = '<empty query>';
        }

        // No Tracking fallback
        if (!config.trackResultsWithFindologic && !config.trackResultsWithAnalytics) {
            flUtils.navigateTo(result.attr('href'));
        }

        // Track with findologic
        if (config.trackResultsWithFindologic) {
            trackerCount++;
        }
        // Track with google analytics
        if (config.trackResultsWithAnalytics) {
            trackerCount++;
        }

        if (config.trackResultsWithFindologic) {
            findologicTracker.trackResultClick(
                result.data('flRequestId'),
                result.data('flItemId'),
                result.data('flItemName'),
                result.data('flResultIndex'),
                query,
                type,
                function () {
                    trackerCount--;
                    if (trackerCount <= 0) {
                        flUtils.navigateTo(result.attr('href'));
                    }
                }
            );
        }

        if (config.trackResultsWithAnalytics) {
            analyticsTracker.trackEvent(
                'findologicEvent',
                'FINDOLOGIC Result Click',
                query,
                result.data('flItemId'),
                () => {
                    trackerCount--;
                    if (trackerCount <= 0) {
                        flUtils.navigateTo(result.attr('href'));
                    }
                },
                { event: EventNames.RESULT_CLICK, query, item_id: result.data('flItemId') }
            );
        }

        return false;
    });
};

/**
 * Method to call whenever the hash changes. It decides whether to do a DI request.
 *
 * There is no need to bind this to the event, as it happens in {@link directIntegration.init}
 * automatically.
 *
 * @param {{}} e jQuery event object.
 * @param {HashChangeEvent} e.originalEvent
 */
directIntegration.hashChangeListener = function (e) {
    const query = directIntegration.getHashQuery();
    if (typeof query.query !== 'undefined') {
        directIntegration.onHashChangeLoadContent();
    } else {
        const oldQuery = directIntegration.getHashQuery(e.originalEvent.oldURL);

        if (oldQuery.type === Request.TYPE_SEARCH) {
            const hasNavigatedBefore = !!directIntegration.firstNavigationPage;

            if (config.directIntegration.navigationType === NavigationType.INSTANT_PRODUCT_LISTING && hasNavigatedBefore) {
                const parsedFirstNavigationPage = urlUtils.parseQueryString(directIntegration.firstNavigationPage);

                // When going back with browser history, determine if the current page
                // is the first navigation page, to prevent reloading and loading
                // the results of the current category page.
                if (parsedFirstNavigationPage.attrib[constants.ATTRIB_CAT_URL][0] === window.location.pathname) {
                    directIntegration.loadContentWhenReady(Request.TYPE_NAVIGATION, directIntegration.firstNavigationPage);
                }
            } else {
                flUtils.reload();
            }
        }

        // Load results if the user goes back to the initial Instant Product Listing page.
        if (oldQuery.type === Request.TYPE_NAVIGATION) {
            if (config.directIntegration.navigationType === NavigationType.INSTANT_PRODUCT_LISTING) {
                directIntegration.loadContentWhenReady(Request.TYPE_NAVIGATION, directIntegration.firstNavigationPage);
            } else {
                flUtils.reload();
            }
        }
    }
};

// Suppressing complexity warning, because refactoring DI isn't worth it.
// eslint-disable-next-line complexity
directIntegration.init = async function (callback, triggeredByReinitialization) {
    unmodifiedShopSystem = ShopSystem.forConfig(config);
    if (directIntegration.preventInitialization) {
        logger.info('Prevented initialization of Direct Integration');

        return;
    }
    if (typeof triggeredByReinitialization === 'undefined') {
        triggeredByReinitialization = false;
    }

    const self = this;

    if (!this.glue && (config.instantFrontend?.productListingPage.enabled ?? false)) {
        const { default: Glue } = await import('../../tscoba/src/InstantFrontend/Glue.ts');

        const analyticsTracker = flAnalyticsTracker.getInstance(config, false);

        this.glue = new Glue(config, this, analyticsTracker);
        this.glue.init();
    }

    if (!this.shoppingGuide && (config.instantFrontend?.shoppingGuide.enabled ?? false)) {
        const { default: ShoppingGuide } = await import('../../tscoba/src/ShoppingGuide/ShoppingGuide.ts');
        this.shoppingGuide = new ShoppingGuide(config, this);
    }

    // Assign the appropriate interception target selector mapping based on the frontend
    // type.
    if (config.frontendConfig.frontendType.indexOf('HTML_3.') === 0) {
        mappedSelectors = selectorMapping['v3'];
    } else {
        mappedSelectors = selectorMapping['classic'];
    }

    searchForm = config.searchFieldElement.parents('form');

    config.searchFieldElement.on('blur', function (event) {
        lastFocusedSearchField = jQuery(event.target);
    });

    searchForm.on('submit.fl-search-form-submit', function (event) {
        const form = this;
        const allFields = config.searchFieldElement;
        /**
         * @type {{searchFieldElements: *, form: *, focusedSearchField: *, lastFocusedSearchField: *}}
         * searchFieldElements is an Array that contains all search-fields on the page form is the current iterated
         * FORM-Element focusedSearchField is an INPUT-Element that has focus, otherwise it contains all search-fields
         * lastFocusedSearchField is an INPUT-Element that had focus
         */
        const formArgs = {
            form,
            searchFieldElements: allFields,
            focusedSearchField: flUtils.getFocusedSearchField(allFields),
            lastFocusedSearchField,
        };

        if (directIntegration.handleSearchSubmit(event, formArgs)) {
            if (!flUtils.isMobileSmartSuggestOverlayActive(config)) {
                if (!flUtils.checkIfSearchFieldBelongsToForm(formArgs)) {
                    event.preventDefault();

                    return false;
                }
            }

            self.sendSearchSubmit(formArgs);

            return revertToShopSearch;
        }
    });

    unmodifiedShopSystem.afterDISubmitEventRegistration(this);

    // Reset tracking of previous query so tests have a fresh state when re-initializing.
    directIntegration.previousQuery = {};

    // Install handler for hashchanges, and check if the hash was initially set already. The handler should only
    // actually trigger a search if the hash has changed.
    jQuery(window).on('hashchange.fl-hashchange-search', this.hashChangeListener);

    // When re-initializing we do not need to load the content twice, since it already was loaded once.
    if (!triggeredByReinitialization) {
        const hashOperationType = this.onHashChangeLoadContent();
        directIntegration.doNavigationRequestIfEnabled(hashOperationType);
    }

    if (typeof callback === 'function') {
        callback();
    }
};

/**
 * Decides whether to do a Classic Navigation, Instant Product Listing or nothing.
 */
directIntegration.doNavigationRequestIfEnabled = function (hashOperationType) {
    if (hashOperationType === undefined) {
        if (directIntegration.glue) {
            switch (config.instantFrontend.productListingPage.navigationConfig.implementation) {
                case 'instant':
                    directIntegration.isInitialNavigationCall = true;
                    directIntegration.loadContentWhenReady(Request.TYPE_NAVIGATION);
                    break;
                case null:
                    // Disabled - do thing.
                    break;
                default:
                    throw Error('Unknown navigation type!');
            }

            return;
        }

        switch (config.directIntegration.navigationType) {
            case NavigationType.DISABLED:
                // Do nothing if navigation is disabled.
                break;
            case NavigationType.CLASSIC_NAVIGATION:
                // If isNavigationQuery=1 FINDOLOGIC will respond with filters only.
                directIntegration.isInitialNavigationCall = true;
                directIntegration.loadContentWhenReady(Request.TYPE_NAVIGATION, 'isNavigationQuery=1');
                break;
            case NavigationType.INSTANT_PRODUCT_LISTING:
                directIntegration.isInitialNavigationCall = true;
                directIntegration.loadContentWhenReady(Request.TYPE_NAVIGATION);
                break;
            default:
                throw Error('Unknown navigation type!');
        }
    }
};

directIntegration.trackNavigation = function () {
    AnalyticsTracker.getInstance(config, true).trackEvent('findologicEvent', 'navigate', 'category', window.location.pathname, null, {
        event: EventNames.NAVIGATE,
        location: window.location.pathname,
    });
};

/**
 * Calls the function flRevealContainers if the function exists only if Instant
 * Product Listing is enabled.
 */
directIntegration.revealContainerIfSupported = function () {
    // This function may not exist in older snippets. Don't limit this to services with Instant Product Listing
    // enabled, because all new integrations regardless of configured navigation type now work with this, even
    // in search. #notabugitsafeatureiswearwinkwink
    if (typeof window.flRevealContainers === 'function') {
        window.flRevealContainers();
    }
};

/**
 * Sends the search-term to Google Analytics.
 *
 * Will try to send data using the current tracking object or fallback to the legacy
 * tracking object. If neither of both are available, no data will be sent.
 *
 * @param {String} fullQueryString
 * @param {String} operation Name of the operation taking place ('search', 'navigation'), so we know whether to
 * track search requests or just filter clicks.
 */
// Refactoring DI isn't worth it.
// eslint-disable-next-line complexity
directIntegration.trackSearchWithGoogle = function (fullQueryString, operation) {
    let queryParameterName;
    let parsedQuery;
    let lastSelectedFilter;
    const params = {};
    const queryNames = ['query', 'keywords'];

    try {
        parsedQuery = urlUtils.parseQueryString(fullQueryString);
        queryParameterName = config.searchFieldElement.attr('name');

        // The backend requires the parameter query _or_ keywords to be present.
        // So we check for one of them to get the entered search query.
        for (const i in queryNames) {
            if (!queryNames.hasOwnProperty(i)) {
                continue;
            }

            const name = queryNames[i];

            if (parsedQuery.hasOwnProperty(name)) {
                // We have to remove hash characters and ampersands since Google Analytics can't handle them
                // properly.
                params[queryParameterName] = parsedQuery[name].replace(/\s*[#&]+\s*/g, ' ');
                break;
            }
        }

        const tracker = AnalyticsTracker.getInstance(config, true);

        if (config.trackFilters) {
            lastSelectedFilter = getLastSelectedFilter(parsedQuery);

            if (typeof lastSelectedFilter !== 'undefined') {
                const eventCategory = `FINDOLOGIC Filter Click: ${operation === Request.TYPE_SEARCH ? 'Search' : 'Navigation'}`;

                tracker.trackEvent('findologicEvent', eventCategory, lastSelectedFilter.name, lastSelectedFilter.value, null, {
                    event: EventNames.FILTER_CLICK,
                    operation,
                    filter_name: lastSelectedFilter.name,
                    filter_value: lastSelectedFilter.value,
                });
            }
        }

        if (operation === Request.TYPE_SEARCH) {
            // Track search fire-and-forget style to avoid callback hell. In almost all cases, the search result
            // page will be viewed for a sufficiently long time to allow for the tracking request to complete.
            tracker.trackSearch(jQuery.param(params));
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
    }
};

/**
 * Returns the current document.readyState
 * Possible values are 'loading', 'interactive', 'complete'
 *
 * @returns {String}
 */
directIntegration.getReadyState = function () {
    return document.readyState;
};

/**
 * Tries to handle an exception using the error callback.
 * Sends a report to the error tracker if the callback is faulty.
 *
 * @param {string} cause Reason that lead to an exception.
 * @param {Error|string|null} e The exception itself. May be null.
 * @param {string} operation Performed DI operation, such as search etc.
 * @param {string} queryString Full search query string.
 * @param {string} originalCallbackName Name of the callback that caused an error.
 * @param {function|undefined} callback It will be called after the error callback, if provided.
 */
directIntegration.callErrorCallbackSafely = function (cause, e, operation, queryString, originalCallbackName, callback) {
    let errorCallback;
    let errorCallbackResult;
    let doFallback = operation !== Request.TYPE_NAVIGATION;

    try {
        // The error callback is defined in the customer-login and passed via the autocomplete config. It needs to
        // be a JS function, so "eval('(function () { /* ... */ })')" can return the parsed function object.
        // eslint-disable-next-line no-eval
        errorCallback = eval(`(${config.directIntegration.callbacks.error})`);

        // Fallback is only intended for search, as there is no reasonable fallback for navigation. Navigation
        // is additional by nature, whereas search is replacing, so whether navigation breaks or not, the same
        // portion of the shop is always visible.
        errorCallbackResult = errorCallback(cause + operation, e);
        doFallback = errorCallbackResult !== false && operation !== Request.TYPE_NAVIGATION;

        if (typeof callback === 'function') {
            callback(doFallback);
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
        if (doFallback) {
            directIntegration.fallBackToShopSearch('faulty error callback');
        }
    }
};

/**
 * Redirects directly to the landingpage url if one is found.
 *
 * @param {jQuery} container The container from the direct integration response.
 * @returns {boolean} Returns true, if landingpage with link was found, otherwise false.
 */
directIntegration.redirectIfLandingpage = function (container) {
    const landingpageItem = container.find('.fl-landing-page:first');

    if (landingpageItem.length > 0) {
        const landingpageLink = landingpageItem.find('a:first').attr('href');
        const landingpageName = landingpageItem.data('flLandingpage');
        const tracker = AnalyticsTracker.getInstance(config, false);

        tracker.trackEvent(
            'findologicEvent',
            'FINDOLOGIC Click',
            'Landingpage',
            landingpageName,
            function () {
                flUtils.navigateTo(landingpageLink);
            },
            { event: EventNames.LANDINGPAGE_CLICK, landingpage: landingpageName }
        );

        return true;
    }

    return false;
};

/**
 * By calling this method, all Findologic functionality will be removed
 * This includes Direct Integration, Smart Suggest, Guided Shopping
 *
 * 1. Prevents future initialization
 * 2. Already existing event listeners will be removed
 * 3. Findologic script is going to be removed from the source (Attention: objects, functions are still in memory)
 */
directIntegration.disableFindologic = function () {
    /**
     * TODO: Load wizard module and set wizard.preventInitialization = true
     */

    const searchFieldElement = config.searchFieldElement;
    // Remove submit handler from search form
    searchFieldElement.parents('form').off('submit.fl-search-form-submit');

    // Remove hashChange event from search field and guided shopping
    jQuery(window).off('hashchange.fl-hashchange-search');
    jQuery(window).off('hashchange.fl-hashchange-guided-shopping');

    // Remove Smart Suggest if already initialized
    if (searchFieldElement.attr('autocomplete')) {
        searchFieldElement.flAutocomplete('destroy');
    }

    /**
     * TODO: Use wizard.wizardClass instead of hard-coded class
     */
    const wizardClass = '.fl-wizard';
    jQuery(document).off('click.fl-guided-shopping-link', wizardClass);
    jQuery(wizardClass).css({
        opacity: 0,
        'pointer-events': 'none',
    });

    // Remove the findologic script tag
    const flScript = document.evaluate(
        `//script[contains(text(), "${config.cdnBaseUrl}")]`,
        document,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
    ).singleNodeValue;
    jQuery(flScript).remove();

    logger.info('FINDOLOGIC features were removed from the site, disable function was called!');
};

directIntegration.sendSearchSubmit = function (formArgs) {
    // Fall back to shop search if a previous attempt to talk to FINDOLOGIC failed.
    if (!revertToShopSearch) {
        const focusedSearchField = formArgs.focusedSearchField.length === 1 ? formArgs.focusedSearchField : lastFocusedSearchField;

        directIntegration.loadContentWhenReady(Request.TYPE_SEARCH, jQuery(formArgs.form).serialize(), null, null, focusedSearchField);
        // Blurring the search field hides the virtual keyboard on mobile devices, which makes it easier to
        // notice that the search results have been loaded.
        formArgs.focusedSearchField.blur();
        // When using Guided Shopping, the guide's filters are present as hidden fields in the search form.
        // Clean them up to avoid persistent filters when triggering a regular search afterwards.
        jQuery(`input[${directIntegration.GUIDED_SHOPPING_MARKER_ATTRIB}]`).remove();
        directIntegration.scrollToTop();
    }
};

/**
 * This method can be override by the callbacks to prevent default search form submit behavior.
 * Return false to prevent default.
 * Return true to have the submit handled as usual.
 */
// eslint-disable-next-line no-unused-vars
directIntegration.handleSearchSubmit = function (submitEvent, formArgs) {
    return true;
};

export default directIntegration;
